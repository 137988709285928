import React from 'react'
import "./style.scss";
import ReactPlayer from 'react-player'
import video from "../../asset/videos/location.mp4";

const VideoPlayer = () => {
  return (
<>
<ReactPlayer
      url={video}
      loop={true}
      width={"100%"}
      height={"100%"}
      playing={true}
      muted={true}
      controls={false}
      className="video"
    />
</>
  )
}

export default VideoPlayer
