import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import "./style.scss";
import { Col, Row } from 'antd';
import img1 from "../../asset/images/offline-marketing-img-1.jpg";
import img2 from "../../asset/images/offline-marketing-img-2.jpg";
import img3 from "../../asset/images/offline-marketing-img-3.jpg";
import InstagramFeedElfsight from '../../commonComponent/social/InstagramFeedElfsight/InstagramFeedElfsight';




const SocialMedia = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  setIsVisible(true);
              }
          });
      });

      if (sectionRef.current) {
          observer.observe(sectionRef.current);
      }

      return () => {
          if (sectionRef.current) {
              observer.unobserve(sectionRef.current);
          }
      };
  }, []);

  return (
    <div className='socialmedia-section' ref={sectionRef}>
        <div className="socialmedia-container">
        
    {/* <div className="instapost-container"> */}
    {/* <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Follow Our</span>{" "}
        <span className="middleTextBold">Journey</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div> */}
    <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
                    <div className={`title-style`}>
                        <span className="prefixTextBold">Follow Our</span>{" "}
                        <span className="middleTextBold">Journey</span>{" "}
                        <span className="backgroundStyle"></span>
                    </div>
                </div>
                <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
                    <div className={`title-style`}>
                        <span className="prefixTextBold">on</span>{" "}
                        <span className="middleTextBold">Instagram</span>{" "}
                        <span className="backgroundStyle"></span>
                    </div>
                </div>
<InstagramFeedElfsight />
    {/* </div> */}
        </div>
      
    </div>
  )
}

export default SocialMedia
