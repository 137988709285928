// import React from "react";
// import "./style.scss";
// import { Form, Input, Button, Row, Col, notification } from 'antd';
// import location_on from "../../asset/images/location_on.svg";
// import phone_in_talk from "../../asset/images/phone_in_talk.svg";
// import mail from "../../asset/images/mail.svg";
// import google_fill from "../../asset/images/mingcute_google-fill.svg";
// import insta_fill from "../../asset/images/mdi_instagram.svg";
// import ReactPlayer from "react-player";
// import video from "../../asset/videos/location.mp4";
// import axios from "axios";

// const ContactUs = () => {
//   // const onFinish = async (values) => {
//   //   try {
//   //     const response = await axios.post('http://localhost:3000/contact', values);
//   //     console.log('Form successfully submitted:', response.data);
//   //     // Optionally, display success message to the user
//   //   } catch (error) {
//   //     console.error('Error submitting form:', error);
//   //     // Optionally, display error message to the user
//   //   }
//   // };
//   const [form] = Form.useForm();

//   const onFinish = async (values) => {
//     try {
//       const response = await axios.post('http://localhost:3000/contact', values);
//       // Reset form fields after successful submission
//       form.resetFields();
      
//       // Show success notification
//       notification.success({
//         message: 'Form Submitted',
//         description: 'Your message has been successfully sent!',
//       });
//       console.log('Form successfully submitted:', response.data);
//     } catch (error) {
//       // Show error notification
//       notification.error({
//         message: 'Submission Failed',
//         description: 'There was an error submitting the form. Please try again.',
//       });
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <div className="contact-section">
//       <div className="contact-container">
//         <div className="contact-top-section">
//           <span>
//             <span className="coma">"</span>
//           Aspiring to launch a food business on a budget? Here's how to start!
//           </span>
//         </div>
//         <div className="contact-content">
//           <div className="contact-item">
//             <div className="title">Get in Touch with us</div>
//             <div className="contact-details">
//               <div className="contact-detail">
//                 <div className="icon">
//                   <img src={location_on} alt="location" />
//                 </div>
//                 <div className="detail">
//                   <div className="heading">Corporate Office Address</div>
//                   <div className="text">
//                     On vihar, Om Vihar, Sector 23A, Gurugram, Haryana 122022
//                   </div>
//                 </div>
//               </div>
//               <div className="contact-detail">
//                 <div className="icon">
//                   <img src={phone_in_talk} alt="phone" />
//                 </div>
//                 <div className="detail">
//                   <div className="heading">Phone Number</div>
//                   <div className="text">1234567890</div>
//                 </div>
//               </div>
//               <div className="contact-detail">
//                 <div className="icon">
//                   <img src={mail} alt="email" />
//                 </div>
//                 <div className="detail">
//                   <div className="heading">E-mail</div>
//                   <div className="text">momomafia@gmail.com</div>
//                 </div>
//               </div>
//             </div>
//             <div className="social-media-section">
//               <div className="heading">
//                 Follow our social media
//                 <div className="social-media-icons">
//                   <img src={google_fill} alt="img" />
//                   <img src={insta_fill} alt="img" />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="contact-form">
//             <div className="title">
//             Send us a Message
//             </div>
// <Form
//             name="contact-form"
//             layout="vertical"
//             onFinish={onFinish}
//             initialValues={{ remember: true }}
//           >
//             <Form.Item
//               label="Name"
//               name="name"
//               // rules={[{ required: true, message: 'Please enter your name!' }]}
//             >
//               <Input placeholder="Name" />
//             </Form.Item>

//             <Form.Item
//               label="Phone"
//               name="phone"
//               // rules={[{ required: true, message: 'Please enter your phone number!' }]}
//             >
//               <Input placeholder="Phone" />
//             </Form.Item>

//             <Form.Item
//               label="Email"
//               name="email"
//               // rules={[{ required: true, message: 'Please enter your email!' }]}
//             >
//               <Input placeholder="Email" />
//             </Form.Item>

//             <Form.Item
//               label="Message"
//               name="message"
//               // rules={[{ required: true, message: 'Please enter your message!' }]}
//             >
//               <Input.TextArea placeholder="Message" rows={4} />
//             </Form.Item>

//             <Form.Item>
//               <Button type="primary" htmlType="submit" block>
//                 Submit
//               </Button>
//             </Form.Item>
//           </Form>
//           </div>
//         </div>
//         <div className="player-wrapper">
//         <ReactPlayer
//                 url={video}
//                 loop={true}
//                 width={"100%"}
//                 height={"100%"}
//                 playing={true}
//                 muted={true}
//                 controls={false}
//                 className="video"
//               />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;



import React from "react";
import "./style.scss";
import { Form, Input, Button, Row, Col, notification } from 'antd';
import location_on from "../../asset/images/location_on.svg";
import phone_in_talk from "../../asset/images/phone_in_talk.svg";
import mail from "../../asset/images/mail.svg";
import google_fill from "../../asset/images/google_fill.svg";
import insta_fill from "../../asset/images/insta_fill.svg";
import ReactPlayer from "react-player";
import video from "../../asset/videos/location.mp4";
import axios from "axios";

const ContactUs = () => {
  const [form] = Form.useForm(); // Initialize form instance

  const onFinish = async (values) => {
    try {
      const response = await axios.post('http://localhost:3000/contact', values);
      
      // Reset form fields after successful submission
      form.resetFields();
      
      // Show success notification
      notification.success({
        message: 'Form Submitted',
        description: 'Your message has been successfully sent!',
      });
      console.log('Form successfully submitted:', response.data);
    } catch (error) {
      // Show error notification
      notification.error({
        message: 'Submission Failed',
        description: 'There was an error submitting the form. Please try again.',
      });
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="contact-section">
      <div className="contact-container">
        <div className="contact-top-section">
          <span>
            <span className="coma" >"</span>
               <span style={{whiteSpace: "pre-line"}}>
               {`Aspiring to launch a food\nbusiness on a budget?\nHere's how to start!`}
               </span>
          </span>
        </div>
        <div className="contact-content">
          <div className="contact-item">
            <div className="title">Get in Touch with us</div>
            <div className="contact-details">
              <div className="contact-detail">
                <div className="icon">
                  <img src={location_on} alt="location" />
                </div>
                <div className="detail">
                  <div className="heading">Corporate Office Address</div>
                  <div className="text">
                    On vihar, Om Vihar, Sector 23A, Gurugram, Haryana 122022
                  </div>
                </div>
              </div>
              <div className="contact-detail">
                <div className="icon">
                  <img src={phone_in_talk} alt="phone" />
                </div>
                <div className="detail">
                  <div className="heading">Phone Number</div>
                  <div className="text">+91 78368 43345</div>
                </div>
              </div>
              <div className="contact-detail">
                <div className="icon">
                  <img src={mail} alt="email" />
                </div>
                <div className="detail">
                  <div className="heading">E-mail</div>
                  <div className="text">saket@themomosmafia.com</div>
                </div>
              </div>
            </div>
            <div className="social-media-section">
              <div className="heading">
                Follow our social media
                <div className="social-media-icons">
                  <img src={google_fill} alt="img" />
                  <img src={insta_fill} alt="img" />
                </div>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <div className="title">
            Send us a Message
            </div>
            <Form
              form={form} // Ensure form instance is connected
              name="contact-form"
              layout="vertical"
              onFinish={onFinish}
              initialValues={{ remember: true }}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name!' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
                rules={[{ required: true, message: 'Please enter your phone number!' }]}
              >
                <Input placeholder="Phone" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please enter your email!' }]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: 'Please enter your message!' }]}
              >
                <Input.TextArea placeholder="Message" rows={4} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="player-wrapper">
          <ReactPlayer
            url={video}
            loop={true}
            width={"100%"}
            height={"100%"}
            playing={true}
            muted={true}
            controls={false}
            className="video"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
