import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Routers } from "react-router-dom";


import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Routers>
    <App />
  </Routers>
);
