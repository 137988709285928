

import React from 'react';
import "./style.scss";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import icon from "../../asset/images/icon_8.svg";
import icon1 from "../../asset/images/icon_9.svg";
import icon2 from "../../asset/images/icon_10.svg";


// Average Order Value
const averageOrderValue = 269;

// Updated data based on the image with actual values and calculated percentages
const data = [
    { name: "Zomato Fee", text: "Service fees & payment mechanism fees", value: 73, percentage: (73 / averageOrderValue) * 100 },
    { name: "Taxes", text: "Taxes on service fee 18% on Service fee &\n Gst Collected by customers", value: 27, percentage: (27 / averageOrderValue) * 100 },
    { name: "Refund/Cancellation", text: "", value: 3, percentage: (3 / averageOrderValue) * 100 },
    { name: "CAC/Marketing/CPC", text: "", value: 19, percentage: (19 / averageOrderValue) * 100 },
    { name: "Royalty", text: "5% on payout of Rs. 147", value: 7, percentage: (7 / averageOrderValue) * 100 },
    { name: "COGS", text: "", value: 52, percentage: (52 / averageOrderValue) * 100 },
    { name: "Fixed Cost", text: "On 50 Orders per day", value: 40, percentage: (40 / averageOrderValue) * 100 },
    { name: "Profit", text: "", value: 49, percentage: (49 / averageOrderValue) * 100 },
];

const COLORS = [
    "#0088FE", "#00C49F", "#FFBB28", "#FF8042", 
    "#FFB6C1", "#FF7F50", "#87CEFA", "#DAA520"
];

// Custom label to show the name above the line and percentage below the line
const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, name, percentage }) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      <tspan x={x} dy="-1.2em">{name}</tspan>  {/* Label above */}
      <tspan x={x} dy="1.2em">{percentage.toFixed(1)}%</tspan> {/* Percentage below */}
    </text>
  );
};

const UnitEconomics = () => {
  return (
    <div className='unit-economics-section'>
        <div className="unit-economics-container">
          <div className="heading">Unit Economics & ROI</div>
          <div className="top-section">
            <div className="left_side">
              <span className='title'>TOTAL INVESTMENT</span>
              <span className='text'>Rs. 3,10,000</span>
            </div>
            <div className="right_side">
              <div>
                <img src={icon} alt='icon' />
                <div className='text'>Need <span>23 Orders per day</span> to operationally breakeven </div>
              </div>
              <div>
              <img src={icon} alt='icon' />
                <div className='text'>ROI in <span>5-6 Months or on 6000 orders</span> which so ever is faster </div>
              </div>
            </div>
          </div>
      <div className="middle-section">
        <div className='unit-economics'>
      <div className='title'>UNIT ECONOMICS</div>
      <div className='economics-container'>
        <div className='row'>
          <div className="left-side">
          <span className='label'>Average Order Value</span>
          <span className='label-text'>Based on last 1000 orders</span>
          </div>
          <div className='value'>Rs. {averageOrderValue}</div>
          <div className='percentage'></div>
        </div>

        {data.map((item, index) => (
          <div className='row' key={index}>
            <div className="left-side">
            <span className='label'>{item.name}</span>
            <span className='label-text'>{item.text}</span>
            </div>
            <div className='value'>
              Rs. {item.value}
            </div>
            <div className='percentage'>{item.percentage.toFixed(1)}%</div>
          </div>
        ))}
      </div>
    </div>
        <div className="chart-container">
          <ResponsiveContainer width={600} height={400}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={true}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomLabel}  // Use custom label renderer
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              {/* <Legend /> */}
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="bottom-section">
        <div className="left_side">
          <img src={icon1} alt='icon' />
          <span className='text'>Download The Calculator</span>
        </div>
        <div className="right_side">
          <img src={icon2} alt='icon' />
          <span className='text'>For details watch the video</span>
        </div>
      </div>
    </div>
    </div>
  )
}

export default UnitEconomics;
