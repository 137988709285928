import { Layout } from 'antd'
import "./style.scss";
import React from 'react'
import { CustomHeader as Header } from '../header/Header'
import Routers from '../../Routers/Routers'
import { CustomFooter as Footer } from '../footer/Footer';

export const CustomLayout = () => {
  return (
    <Layout>
    <Header />
   <Routers />
   <Footer />
  </Layout>
  )
}
