import img1 from "../../asset/images/training_img-1.webp";
import img2 from "../../asset/images/training_img-2.webp";
import img3 from "../../asset/images/training_img-3.webp";
import img4 from "../../asset/images/training_img-4.webp";

export const data = [
  {
    id: "1",
    text: (
      <div className="item_text">
        Training program to help you run your own cart/stall
      </div>
    ),
    img: img1,
  },
  {
    id: "2",
    text: <div className="item_text">Operational Training for your staff</div>,
    img: img2,
  },
  {
    id: "3",
    text: (
      <div className="item_text">Monthly Review (Personal & Online both)</div>
    ),
    img: img3,
  },
  {
    id: "4",
    text: <div className="item_text">Partner complaint management system </div>,
    img: img4,
  },
];
