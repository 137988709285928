import React from 'react';
import "./style.scss";
import software_img from "../../asset/images/software-png.png";

const SoftwareDrivenOperation = () => {
  return (
    <div className='software-driven-section'>
        <div className="software-driven-container">
            <div className="heading">
            Software Driven Operations
            </div>
            <div className="sub-heading">
            Manage your business from anywhere with ease.
            </div>
            <div className='cards'>
                <span>Business Dashboard</span>
                <span>Daily Stock In&out</span>
                <span>Inventory</span>
                <span>Staff Attendance</span>
                <span>Live CCTV Feed</span>
                <span>Stock Inventory management</span>
                <span>Delivery Management</span>
                <span>Help & Complaints</span>
            </div>
            <div className="text">
            Momo Mafia is not just a cloud kitchen franchise; it's a culinary revolution powered by a robust software system designed to build and scale brands effectively. We strike the perfect balance between exceptional momo craftsmanship and cutting-edge technological infrastructure, enabling franchise partners to grow seamlessly while delivering top-quality food. Join us in expanding this innovative brand and benefit from a model that’s engineered for success.
            </div>
           <img src={software_img} alt='software_img' className="software-section" />
        </div>
      
    </div>
  )
}

export default SoftwareDrivenOperation
