import React, { useEffect, useRef, useState } from 'react';
import "./style.scss";
import { CustomButton as Button } from '../../commonComponent/button/Button';
import banner_img from "../../asset/images/franchise_banner.png";
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import { Row, Col } from 'antd'; // Import Ant Design Grid
import { useNavigate } from 'react-router-dom';

const FranchiseBanner = () => {
  const [counterState, setCounterState] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const franchiseRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (franchiseRef.current) {
      observer.observe(franchiseRef.current);
    }

    return () => {
      if (franchiseRef.current) {
        observer.unobserve(franchiseRef.current);
      }
    };
  }, []);

  return (
    <div className='banner_section' ref={franchiseRef}>
      <Row className="banner_top_section" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <div className={`left_side_section ${isVisible ? "" : ""}`}>
            <div className="top">
              <div className="title">
                Serving Hygienic Momos & Empowering Entrepreneurs to Launch Affordable Franchise Food Carts
              </div>
              <div className="text">
                A <span>Tech-driven infrastructure</span> & supply chain to deliver <span>hygienic & delicious</span> momos.
              </div>
              <div className="buttons">
                <Button customBtnClass="btn primaryButton" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfYji4ysnQAnI3euEVW4jwyZr1uzvvUacI7zT-pR89EJ9g80A/viewform'}>Apply for Franchise</Button>
                {/* <Button customBtnClass="btn secondSecondaryButton" onClick={() => navigate('/cart-franchise')}>Know More</Button> */}
                <div className='know-more-button' onClick={() => navigate('/cart-franchise')}>Know More</div>
              </div>
            </div>

            <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)} className="counter-container-desktop">
              <div className="bottom counter-section">
                <div>
                  <div className="number">
                    {counterState && <CountUp start={0} end={32} duration={2.5} />}
                  </div>
                  <div className="number_title">Momo Cart</div>
                </div>
                <div className='border'></div>
                <div>
                  <div className="number">
                    {counterState && <CountUp start={0} end={11} duration={2.5} suffix="+" />}
                  </div>
                  <div className="number_title">Cities</div>
                </div>
                <div className='border'></div>
                <div>
                  <div className="number">
                    {counterState && <CountUp start={0} end={24} duration={2.5} suffix=" lac+" />}
                  </div>
                  <div className="number_title">Momo sold</div>
                </div>
                <div className='border'></div>
                <div>
                  <div className="number">
                    {counterState && <CountUp start={0} end={250000} duration={2.5} suffix="+" />}
                  </div>
                  <div className="number_title">Customer Served</div>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </Col>

        <Col xs={24} md={12} className={`right_side_section ${isVisible ? "text-focus-in" : ""}`}>
          <img src={banner_img} alt='banner_img' />
        </Col>
      </Row>

      <ScrollTrigger 
            onEnter={() => setCounterState(true)} 
            onExit={() => setCounterState(false)}
            className="counter-container-mobile"
          >
            <div className="bottom counter-section">
              <div>
                <div className="number pb-3">
                  {counterState && (
                    <CountUp start={0} end={32} duration={2.5} />
                  )}
                </div>
                <div className="number_title">Momo Cart</div>
              </div>
              <div className='border'></div>
              <div>
                <div className="number pb-3">
                  {counterState && (
                    <CountUp start={0} end={11} duration={2.5} suffix="+" />
                  )}
                </div>
                <div className="number_title">Cities</div>
              </div>
              <div>
                <div className="number pb-3">
                  {counterState && (
                    <CountUp start={0} end={24} duration={2.5} suffix=" lac+" />
                  )}
                </div>
                <div className="number_title">Momo sold</div>
              </div>
              <div className='border'></div>
              <div>
                <div className="number pb-3">
                  {counterState && (
                    <CountUp start={0} end={250000} duration={2.5} suffix="+" />
                  )}
                </div>
                <div className="number_title">Customer Served</div>
              </div>
            </div>
          </ScrollTrigger> 

      <div className="banner_bottom_section">
        <div className="photobanner flex align-items-center gap-4">
          <div className="text">9 Delhi</div>
          <div className="line">|</div>
          <div className="text">1 Manesar </div>
          <div className="line">|</div>
          <div className="text">10 Gurgaon</div>
          <div className="line">|</div>
          <div className="text">2 Greater Noida</div>
          <div className="line">|</div>
          <div className="text">2 Jaipur</div>
          <div className="line">|</div>
          <div className="text">1 Rohtak</div>
          <div className="line">|</div>
          <div className="text">1 Bhiwani</div>
          <div className="line">|</div>
          <div className="text">1 Faridabad</div>
          <div className="line">|</div>
          <div className="text">1 Rewari</div>
          <div className="line">|</div>
          <div className="text">3 Bulandshahar</div>
          <div className="line">|</div>
          <div className="text">1 Sonipat</div>
          <div className="line">|</div>
          <div className="text">9 Delhi</div>
          <div className="line">|</div>
          <div className="text">1 Manesar</div>
          <div className="line">|</div>
          <div className="text">10 Gurgaon</div>
          <div className="line">|</div>
          <div className="text">2 Greater Noida</div>
          <div className="line">|</div>
          <div className="text">2 Jaipur</div>
          <div className="line">|</div>
          <div className="text">1 Rohtak</div>
          <div className="line">|</div>
          <div className="text">1 Bhiwani</div>
          <div className="line">|</div>
          <div className="text">1 Faridabad</div>
          <div className="line">|</div>
          <div className="text">1 Rewari</div>
          <div className="line">|</div>
          <div className="text">3 Bulandshahar</div>
          <div className="line">|</div>
          <div className="text">1 Sonipat</div>
        </div>
      </div>   
    </div>
  );
};

export default FranchiseBanner;
