import "./App.scss";
import { CustomLayout as Layout } from "./commonComponent/layout/Layout";
// import {CustomLayout as Layout} from "./CommonComponents/layout/Layout";


function App() {
  return (
    // <Layout />
    <Layout />
    
  );
}

export default App;
