import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import "./style.scss";
import { Col, Row } from "antd";
import { data } from "./data";
import icon1 from "../../asset/images/icon_11.svg";
import icon2 from "../../asset/images/icon_12.svg";
import icon3 from "../../asset/images/icon_13.svg";
import icon4 from "../../asset/images/icon_14.svg";
import icon5 from "../../asset/images/icon_15.svg";
import icon6 from "../../asset/images/icon_16.svg";

const cardData = [
  {
    id: 1,
    img: icon1,
    text: "FSSAI Registration",
  },
  {
    id: 2,
    img: icon2,
    text: "Swiggy Onboarding",
  },
  {
    id: 3,
    img: icon3,
    text: "Petpooja Setup and Integration",
  },
  {
    id: 4,
    img: icon4,
    text: "Photoshoot",
  },
  {
    id: 5,
    img: icon5,
    text: "Zomato Onboarding",
  },
  {
    id: 6,
    img: icon6,
    text: "Videoshoot",
  },
];

const TrainingSupport = () => {
  const location = useLocation(); // Get the current location

  return (
    <>
      <div className="training-section">
        <div className="training-container">
          <div className="heading">Training, Support & Integrations</div>
          <div className="training-content">
            <Row gutter={[24, 24]}>
              {data.map((item) => (
                <Col key={item.id} xs={12} xl={12}>
                  <div className={`card card_${item.id}`}>
                    <div className="card_img">
                      <img src={item.img} alt="item_img" />
                    </div>
                    {item.text}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>

      {/* Conditionally render card section if the route is not '/cart-franchise' */}
      {location.pathname !== "/cart-franchise" && (
        <div className="card-section">
          <div className="card-container">
            <Row gutter={[24, 24]}>
              {cardData.map((item) => (
                <Col key={item.id} xs={12} xl={12}>
                  <div className={`card card_${item.id}`}>
                    <img src={item.img} alt="item_img" />
                    <span className="text">{item.text}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainingSupport;
