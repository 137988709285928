import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from 'antd';
import "./style.scss";
import img1 from "../../asset/images/img1.png";
import img2 from "../../asset/images/img2.png";
import img3 from "../../asset/images/img3.png";
import img4 from "../../asset/images/img4.png";

const data = [
    {
        id: 1,
        title: "Perfect for Anyone Wanting to Start a Low-Cost Food Business",
        text: "Aspiring to launch a food business on a budget? Here's how to start!",
        img: img1
    },
    {
        id: 2,
        title: "If you are Looking for a Side Income Source",
        text: "Looking to boost your income with a side hustle? Here's the perfect solution!",
        img: img2
    },
    {
        id: 3,
        title: "Support your Friends & Family in Starting Food Cart Businesses",
        text: "Want to empower your friends and family to start their own business? Here's how you can help!",
        img: img3
    },
    {
        id: 4,
        title: "Already in food business and want to expand",
        text: "Already thriving in the food industry, you’re ready to scale and expand into new markets and opportunities.",
        img: img4
    }
];

const WhoCanTakeFranchise = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div 
          className={`who-can-take-franchise-section`} 
          ref={sectionRef}
        >
            <div className="who-can-take-franchise-container">
                <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
                    <div className={`title-style`}>
                        <span className="prefixTextBold">Who can take the</span>{" "}
                        <span className="middleTextBold">Franchise</span>{" "}
                        <span className="backgroundStyle"></span>
                    </div>
                </div>

                <Row gutter={[16, 16]} className="content">
                    {data.map((item) => (
                        <Col
                          key={item.id}
                          xs={12} sm={12} md={12} lg={6} xl={6}
                          className={`content-item`}
                        >
                            <img src={item.img} alt="franchise-img" className="franchise-img" />
                            <div className="title">{item.title}</div>
                            <div className="text">{item.text}</div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default WhoCanTakeFranchise;
