import React, { lazy, useState } from 'react'
import "./ChatWithSales.scss";
import callUsImg from "../../asset/images/callusImg.png";
import tickIcon from "../../asset/images/tikIcon.svg";
import { CustomButton as Button } from '../../commonComponent/button/Button';

const ChatWithSales = ({setBookingFlag}) => {
    
  return (
    <div id='master-franchise' className='chat-section' >
        <div className='chat-container'>
            <div className='left-side'>
                <img src={callUsImg} alt='callUsImg'  />
            </div>
            <div className='right-side'>
                <div className='heading'>Looking For Master Franchise </div>
                <div className='tik-container'>
                    <div className='tik-content' >
                        <img src={tickIcon} alt='tikIcon' />
                        <div className='text'>want to open more than 3 carts in your city?</div>
                    </div>
                    <div className='tik-content' >
                        <img src={tickIcon} alt='tikIcon' />
                        <div className='text'>have a large project in mind?</div>
                    </div>
                    <div className='tik-content' >
                        <img src={tickIcon} alt='tikIcon' />
                        <div className='text'>need guidance in building a custom franchise package?</div>
                    </div>
                </div>
                <Button customBtnClass="btn secondPrimaryButton">CHAT WITH SALES</Button> 
               
            </div>
        </div>
      
    </div>
  )
}

export default ChatWithSales
