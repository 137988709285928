import React from 'react';
import './style.scss';

const FranchiseFeatures = () => {
  return (
  <div className='franchise-section'>
  <div className="franchise-container">

    <div className="top-container">
      <div className="left_side">
      <div className="top-heading-section">
      <div className="coma">“</div>
       <div className="right-side flex flex-column gap-1">
       <div className="centered-container-first">
      <div className="title-style">
        <span className="prefixTextBold">What Makes</span>{" "}
        <span className="middleTextBold">The Momos </span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
    <div className="centered-container-second">
      <div className="title-style">
        <span className="prefixTextBold">Mafia Franchise</span>{" "}
        <span className="middleTextBold">Unique</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
       </div>
    </div>
   <div className='horizental-card'>
   <div class="">
        <div className="card card-1">
   <div className="card-bg">
   {`Low Investment\n Franchise Model`}
   </div>
   </div>
   <div className="text">
   Offering low investment franchise options so that it can suit your budget.
   </div>
        </div>
        <div class="">
        <div className="card card-2">
   <div className="card-bg">
   {`In-house Momo\n Factory`}
   </div>
   </div>
   <div className="text">
   In-house kitchen - sop based infra to ensure HQ and QA momos are made and delivered
   </div>
        </div>
   </div>
      </div>
      <div className="right_side">
      <div class="">
<div className="card card-3 card-mobile-3">
   <div className="card-bg">
   {`Provide Well\n Trained Staff`}
   </div>
   </div>
   <div className="text">
   Providing well trained staff to run the Food Cart
   </div>
</div>
      </div>
    </div>
    <div className='bottom-container'>
    <div class="">
<div className="card card-4">
   <div className="card-bg">
  {` Technology\n Driven Operations`}
   </div>
   </div>
   <div className="text">
   Technology driven infrastructure & supply chain to handle the complex operations
   </div>
        </div>
        <div class="">
<div className="card card-5">
   <div className="card-bg">
   {`Zero Wastage\n Model`}
   </div>
   </div>
   <div className="text">
   It minimizes waste at all stages, enhancing sustainability and efficiency while reducing environmental impact.
   </div>
        </div>
        <div class="bottom-container-last">
        <div className="card card-6 card-mobile-6">
   <div className="card-bg">
  {` 45 Menu Items\n from 5 base Momo`}
   </div>
   </div>
   <div className="text">
   Creating a full fledged menu of Ready to cook 45 items from 5 base momos
   </div>
        </div>
    </div>
 </div>
</div>
  );
};

export default FranchiseFeatures;




