import React from 'react';
import "./style.scss";
import img1 from "../../asset/images/img5.png";
import img2 from "../../asset/images/img6.png";
import img3 from "../../asset/images/img7.png";
import img4 from "../../asset/images/img8.png";
import img5 from "../../asset/images/img9.png";
import img6 from "../../asset/images/img10.png";
import img7 from "../../asset/images/img11.png";
import img8 from "../../asset/images/img12.png";
import img9 from "../../asset/images/img13.png";
import img10 from "../../asset/images/img14.png";
import img11 from "../../asset/images/img15.png";
import zomato from "../../asset/images/zomato.svg";
import { Link } from 'react-router-dom';


const data = [
    {
        id: 1,
        img: img1,
        title: "Garden Fresh Veg"
    },
    {
        id: 2,
        img: img2,
        title: "Paneer Supreme"
    },
    {
        id: 3,
        img: img3,
        title: "Darjeeling Chicken"
    },
    {
        id: 4,
        img: img4,
        title: "Cheese Corn Fusion"
    },
    {
        id: 5,
        img: img5,
        title: "Soya Protein Packed"
    },
    {
        id: 6,
        img: img6,
        title: "Veg Mo Burger"
    },
    {
        id: 7,
        img: img7,
        title: "Paneer Mo Burger"
    },
    {
        id: 8,
        img: img8,
        title: "Chicken Mo Burger"
    },
    {
        id: 9,
        img: img9,
        title: "Veg Momos"
    },
    {
        id: 10,
        img: img10,
        title: "Veg Spring Roll"
    },
    {
        id: 11,
        img: img11,
        title: "Veg Spring Roll"
    }
]
const ProductCategory = () => {
  return (
    <div className='product-category-section'>
        <div className='product-category-container'>
            <div className="heading">Product Category</div>
            <div className="text">Our menu at The Momo Mafia is designed to be both diverse and efficient. With just 5 base momo types and 2 spring roll options, we offer 5-6 variations for each, allowing us to provide an extensive selection while keeping inventory management simple. This focused approach ensures freshness and quality. Additionally, with unique offerings like burgers and momo salads, our menu stands out from the competition, giving customers a full-fledged experience while maintaining operational efficiency.</div>
            <div className='cards'>
                <span>Momo</span>
                <span>Mo-Burgers</span>
                <span>Spring Rolls</span>
                <span>Momo Salad</span>
            </div>
            <div className='content-item'>
                {
                    data.map((item) => (
<div key={item.id}  // Breakpoints for responsiveness
              className={`card card-${item.id}`} >

                <img src={item.img} alt='img' />
                <div className='title'>{item.title}</div>

</div>
                    ))
                }
            </div>
            <div className="bottom-section">
                <div className="zomato-text">
                CLICK TO CHECK 
                <img src={zomato} alt='zomato' />
                 LISTING 
                </div>
                <Link to="https://zomato.onelink.me/xqzv/m4qwex0s" className="zomato-link">https://zomato.onelink.me/xqzv/m4qwex0s</Link>
            </div>
        </div>
      
    </div>
  )
}

export default ProductCategory
