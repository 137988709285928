import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd"; // Import Dropdown and Menu
import { DownOutlined } from "@ant-design/icons"; // Import the dropdown icon
import "./style.scss";
import logo from "../../asset/images/logo.svg";
import { CustomButton as Button } from "../button/Button";

export const CustomHeader = () => {
  const [activeIndex, setActiveIndex] = useState(null); // State to track active tab
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { name: "Franchise overview", link: "/#franchise-overview" },
    { name: "How it works", link: "/#how-it-works" },
    { name: "Master Franchise", link: "/#master-franchise" },
    {
      name: "Packages", // Dropdown for Offering
      // link: "/#offering",
      dropdown: [
        { name: "Cart Franchise", link: "/cart-franchise" },
        { name: "Cloud Kitchen franchise", link: "/cloud-kitchen" }
      ],
    },
    { name: "Gallery", link: "/#gallery" },
    { name: "FAQ", link: "/#faq" },
    { name: "Contact Us", link: "/contact-us" },
  ];

  const handleItemClick = (index, item) => {
    setActiveIndex(index); // Set active tab
    setShowMobileMenu(false);

    if (item.link.includes("#")) {
      const [path, hash] = item.link.split("#");
      navigate(path, { replace: true }); // Navigate to the path
      setTimeout(() => {
        document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Slight delay to ensure page has navigated
    } else {
      navigate(item.link);
    }
  };

  const handleMenuClick = ({ key }) => {
    // When an item is clicked from the dropdown, activate the Offering tab
    setActiveIndex(3); // Index of Offering tab
    navigate(key); // Navigate to the route
    setShowMobileMenu(false);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  // Menu for Offering Dropdown
  const offeringMenu = (
    <Menu onClick={handleMenuClick}>
       <Menu.Item key="/cart-franchise">Cart Franchise</Menu.Item>
      <Menu.Item key="/cloud-kitchen">Cloud Kitchen Franchise</Menu.Item>
     
    </Menu>
  );

  return (
    <nav className="main-nav">
      <div className={`mobile-menu ${showMobileMenu ? "overlay" : ""}`}>
        <div className="mobile-nav-item">
          <ul id="navbar">
            {menuItems.map((item, index) => (
              <li key={index}>
                {item.dropdown ? (
                  <Dropdown overlay={offeringMenu} trigger={['hover']}>
                    <div
                      className={`menu-item ${activeIndex === 3 ? 'active' : ''}`} // Only active on dropdown item click
                    >
                      {item.name}
                      <DownOutlined style={{ marginLeft: "5px" }} /> {/* Dropdown Icon */}
                    </div>
                  </Dropdown>
                ) : (
                  <div
                    className={`menu-item ${activeIndex === index ? "active" : ""}`}
                    onClick={() => handleItemClick(index, item)}
                  >
                    {item.name}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <Button customBtnClass="login-btn-mobile primaryButton" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfYji4ysnQAnI3euEVW4jwyZr1uzvvUacI7zT-pR89EJ9g80A/viewform'}>Apply For Franchise</Button>
      </div>

      {/* <div > */}
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>
      {/* </div> */}

      <div className="menu-link">
        <ul id="navbar">
          {menuItems.map((item, index) => (
            <li key={index}>
              {item.dropdown ? (
                <Dropdown overlay={offeringMenu} trigger={['hover']}>
                  <div
                    className={`menu-item ${activeIndex === 3 ? "active" : ""}`} // Activate only when dropdown item is clicked
                  >
                    {item.name}
                    <DownOutlined style={{ marginLeft: "5px" }} /> {/* Dropdown Icon */}
                  </div>
                </Dropdown>
              ) : (
                <div
                  className={`menu-item ${activeIndex === index ? "active" : ""}`}
                  onClick={() => handleItemClick(index, item)}
                >
                  {item.name}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="last-column">
        <Button customBtnClass="login-btn-desktop primaryButton" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfYji4ysnQAnI3euEVW4jwyZr1uzvvUacI7zT-pR89EJ9g80A/viewform'}>Apply For Franchise</Button>
        <div className="mobile-menu-button-wrapper">
          <button
            className={`hamburger hamburger--spin ${showMobileMenu ? "is-active" : ""}`}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </nav>
  );
};

