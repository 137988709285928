import React, { useEffect } from 'react';
import "./style.scss";

const InstagramFeedElfsight = () => {
  useEffect(() => {
    // Dynamically add the Elfsight script to the page
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="instagram-feed-elfsight">
      {/* Elfsight widget embed code */}
      <div className="elfsight-app-de7ba520-2c10-4e0a-8cea-6b8248705918" data-elfsight-app-lazy></div>
    </div>
  );
};

export default InstagramFeedElfsight;