import React from "react";
import "./style.scss";
import { Col, Row } from "antd";
import img1 from "../../asset/images/icon_1.svg";
import img2 from "../../asset/images/icon_2.svg";
import img3 from "../../asset/images/icon_3.svg";
import img4 from "../../asset/images/icon_4.svg";
import img5 from "../../asset/images/icon_5.svg";
import img6 from "../../asset/images/icon_6.svg";

const data = [
  {
    id: 1,
    img: img1,
    title: (
      <div className="title">
        <span>Technology-Driven</span>
        <br />
        Operations Infrastructure
      </div>
    ),
    text: "Digital ordering, inventory management, and a comprehensive dashboard to fully control your business remotely.",
  },
  {
    id: 2,
    img: img2,
    title: (
      <div className="title">
        <span> High-Profit Potential:</span>
        <br />
        80% Gross Margin
      </div>
    ),
    text: "Our in-house production allows us to offer this exceptional gross margin to our franchise partners.",
  },
  {
    id: 3,
    img: img3,
    title: (
      <div className="title">
        Low Investment, High  <br /> Returns & {" "}<span>Quick ROI</span>
      </div>
    ),
    text: "Investment starts from just ₹2.5 lakh with higher returns due to lower COGS. Achieve ROI in under 5-6 months with our 0% wastage model—less inventory, more dishes.",
  },
  {
    id: 4,
    img: img4,
    title: (
      <div className="title">
        <span>Chef-Less Multi-Brand </span>
        <br />
        Kitchen
      </div>
    ),
    text: "47% of momo are consumed in the ₹100-200 range, and 23% in the ₹200-300 range. Our kitchen cater to both segments—all from a single kitchen.",
  },
  {
    id: 5,
    img: img5,
    title: (
      <div className="title">
        One Months
        <br />
        <span>Royalty-Free</span>
      </div>
    ),
    text: "To support you during the early days of your business, we waive all royalty fees for the firsts month",
  },
  {
    id: 6,
    img: img6,
    title: (
      <div className="title">
        Lifetime {" "}
        <span>Free Digital  <br />Marketing</span>
      </div>
    ),
    text: "TMM provides centralized marketing for life, ensuring every partner benefits from our ongoing promotional efforts.",
  },
];
const WhyChooseTMM = () => {
  return (
    <div className="why-choose-tmm-section">
      <div className="continue-section">
        <div className="continue-container">
          <span>Gateway for young generation food business aspirants.</span>{" "}
          <span>Gateway for young generation food business aspirants.</span>{" "}
          <span>Gateway for young generation food business aspirants.</span>{" "}
          <span>Gateway for young generation food business aspirants.</span>{" "}
          <span>Gateway for young generation food business aspirants.</span>{" "}
        </div>
      </div>
      <div className="why-choose-tmm-container">
        <div className="why-choose-tmm-heading">
          Why choose TMM
          <br />
          <span>Cloud Kitchen</span> Franchisee?
        </div>

        <Row gutter={[32, 32]} className="content">
          {" "}
          {/* Ant Design's Row component */}
          {data.map((item) => (
            <Col
              key={item.id}
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8} // Breakpoints for responsiveness
              className="content-item"
            >
              <img src={item.img} alt="franchise-img" className="img" />
              {item.title}
              <div className="text">{item.text}</div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default WhyChooseTMM;
