

import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import b1 from "../../asset/images/b1.png";
import b2 from "../../asset/images/b2.png";
import b3 from "../../asset/images/b3.png";
import b4 from "../../asset/images/b4.png";
import b5 from "../../asset/images/b5.png";
import b6 from "../../asset/images/b6.png";
import b7 from "../../asset/images/b7.png";
import b8 from "../../asset/images/b8.png";
import b9 from "../../asset/images/b9.png";
import b10 from "../../asset/images/b10.png";
import b11 from "../../asset/images/b11.png";
import b12 from "../../asset/images/b12.png";
import b13 from "../../asset/images/b13.png";

const images = [
  [b1, b2, b3],
  [b4, b5],
  [b6, b7, b8],
  [b9, b10],
  [b11, b12, b13]
];

const Gallery = () => {
  const [isVisible, setIsVisible] = useState(false);
  const galleryRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (galleryRef.current) {
      observer.observe(galleryRef.current);
    }

    return () => {
      if (galleryRef.current) {
        observer.unobserve(galleryRef.current);
      }
    };
  }, []);

  return (
    <div 
      id="gallery" 
      className={`gallery-section`}
      ref={galleryRef}
    >
      <div className="gallery-container">
        <div className="heading">
          <div className={`centered-container-1 ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
            <div className="title-style">
              <span className="prefixTextBold">Discover the</span>{" "}
              <span className="middleTextBold">World</span>{" "}
              <span>of</span>{" "}
              {/* <span className="middleTextBold">The</span> */}
              <span className="backgroundStyle"></span>
            </div>
          </div>
          <div className={`centered-container-2 ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
            <div className="title-style">
            <span className=""> The</span>{" "}
              <span className="middleTextBold">The Momos Mafia</span>{" "}
              <span className="backgroundStyle"></span>
            </div>
          </div>
        </div>
        <div className="image-container">
          <table className="main-table">
            <tbody>
              {images.map((column, columnIndex) => (
                <td key={columnIndex}>
                  <table>
                    <tbody>
                      {column.map((image, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>
                            <img
                              src={image}
                              alt={`b${columnIndex * 3 + rowIndex + 1}`}
                              loading="lazy"
                              className={isVisible ? 'text-focus-in' : ''}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
