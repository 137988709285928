import React from "react";
import "./style.scss";

const FinancialOverview = () => {
  return (
    <div className="finantial-overview-section">
      <div className="finantial-overview-container">
        <div className="heading">Financial Overview</div>
        <div className="item-container">
          <div className="text">When you partner with The Momos Mafia to open a cloud kitchen franchise, you’ll be operating within a compact 10x10 ft space, optimized for high efficiency. The initial capital expenditure (CAPEX) is Rs. 1,60,000, ensuring you have everything you need to start strong. A franchise fee of Rs. 90,000 is required for a 2-year tenure. Additionally, a 10% management fee on payouts will help ensure that your operations run smoothly, allowing you to tap into our expertise and support as you grow your business.</div>
          <div className="table">
            <table>
             <thead>
             <tr>
                <th>FORMAT</th>
                <th>ClOUD KITCHEN</th>
              </tr>
             </thead>
             <tbody>
             <tr>
                <td>AREA</td>
                <td>10x10 ft</td>
              </tr>
              <tr>
                {" "}
                <td>CAPEX</td>
                <td>Rs. 1,60,000</td>
              </tr>
              <tr>
                {" "}
                <td>TENURE</td>
                <td>2 YEARS</td>
              </tr>
              <tr>
                {" "}
                <td>FRANCHISE FEE</td>
                <td>Rs. 90,000</td>
              </tr>
              <tr>
                {" "}
                <td>ROYALTY</td>
                <td>5% on payout</td>
              </tr>
             </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverview;
