import React, { useState } from "react";
import "./style.scss";
import { Collapse } from "antd";
import plusIcon from "../../asset/images/plus_icon.svg";
import crossIcon from "../../asset/images/cross_icon.svg";
import { CustomButton as Button } from "../../commonComponent/button/Button";


const itemsMobile = [
  {
    key: "1",
    label: (
      <div className="col-title">What is the initial investment required to start a Momos cart franchise?</div>
    ),
    children: (
      <div className="col-text">
        The total investment varies depending on the location and cart setup, but generally, the initial investment is around [insert amount]. This includes the franchise fee, cart setup, initial stock, and other operational costs.
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div className="col-title">What is included in the franchise fee?</div>
    ),

    children: (
      <div className="col-text">
       The franchise fee covers brand licensing, training, marketing support, Cart Cost, all tools and equipments,T-shirts for staff and some initial stock. It also includes the right to use our brand and access to our proprietary recipes and marketing materials.
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div className="col-title">
Do I need previous experience in the food business to become a franchisee?      </div>
    ),

    children: (
      <div className="col-text">
        No prior experience is required! We provide comprehensive training on cart management, food preparation, customer service, to help you succeed.
      </div>
    ),
  },
  {
    key: "4",
    label: (
      <div className="col-title">
        What kind of training and support do you offer?
      </div>
    ),

    children: (
      <div className="col-text">
        We offer full training on how to operate the Momos cart, prepare the dishes, manage inventory, and handle customer interactions. Ongoing support is also provided through a dedicated franchise support team.
      </div>
    ),

  },
  {
    key: "5",
    label: (
      <div className="col-title">
        How much can I expect to earn as a Momos cart franchisee?
      </div>
    ),
    children: (
      <div className="col-text">
        Earnings vary based on location, customer traffic, and operational efficiency. However, a well-managed cart in a high-traffic area can generate monthly revenues ranging from 1.5 lac 3lac per month
      </div>
    ),

  },
  {
    key: "6",
    label: (
      <div className="col-title">
       Are there any royalty or ongoing fees?
      </div>
    ),

    children: (
      <div className="col-text">
        No there is no royalty on sales and we dont even charge for the franchise fee. you get life time access of free marketing without paying any royalty etc
      </div>
    ),

  },
  {
    key: "7",
    label: (
      <div className="col-title">
        What is the average payback period for the investment?
      </div>
    ),

    children: (
      <div className="col-text">
       The payback period (ROI) typically ranges from 3-4 month, depending on location and operational efficiency.
      </div>
    ),

  },
  {
    key: "8",
    label: (
      <div className="col-title">
       How long does it take to set up a new cart?
      </div>
    ),

    children: (
      <div className="col-text">
       Once the location is verified & the franchise agreement is signed, the cart can be set up and operational within 2 weeks*
      </div>
    ),

  },
];
const HaveQuestions = () => {
  return (
    <div id="faq" className="have-section">
      <div className="have-ques-section">
        <div className="faq-title">FAQs </div>
      <div className="have-ques-container">
        <div className="left-side">
          <div className="heading">Have questions?</div>
            <div className="text">
              {" "}
              Have more questions?
              Book a free one on one call with sales representative
            </div>
            <Button customBtnClass="btn secondPrimaryButton"
            >Book a Call</Button>
        </div>
        <div className="right-side">
          {/* <div className="heading only-mobile">
            Have <br />
            questions?
          </div> */}
          <Collapse
            accordion
            defaultActiveKey={["1"]}
            bordered={false}
            expandIconPosition="end"
            items={itemsMobile}
            expandIcon={({ isActive }) =>
              isActive ? (
                <img src={crossIcon} alt="img" />
              ) : (
                <img src={plusIcon} alt="img" />
              )
            }
          />
          {/* <div className="last only-mobile">
          <div className='text'> Have more questions? <br />
Book a free strategy call. with sales representative</div>
<Button text="Talk to expert" width="168.23px" className="talkBtn" />
          </div> */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default HaveQuestions;
