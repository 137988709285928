import React, { useEffect, useRef, useState } from "react";
import { Row } from 'antd';
import "./style.scss";
import youtube_icon from "../../asset/images/youtube_icon.svg";
import img1 from "../../asset/images/franchise_card-1.png";
import img2 from "../../asset/images/franchise_card-2.png";
import img3 from "../../asset/images/franchise_card-3.png";
import img4 from "../../asset/images/franchise_card-4.png";
import img5 from "../../asset/images/franchise_card-5.png";
import img6 from "../../asset/images/franchise_card-6.png";

import FranchiseOverViewCard from "./FranchiseOverViewCard";

const data = [
  { id: 1, img: img1, text: <div className="card_text">Provide Portable to <br />Fixed <span>Momo Cart & Stall,</span><br /> all in your budget</div>, },
  { id: 2, img: img2, text: <div className="card_text">Supply of High <span>Quality &<br /> Hygienically</span> made <br /> Momo</div>, },
  { id: 3, img: img3, text: <div className="card_text">All types <br />of <span>Serving & Packaging <br /> Material</span></div>, },
  { id: 4, img: img4, text: <div className="card_text">Daily Momo <br /><span>Delivery</span> at super <br /><span>low logistic cost</span></div>, },
  { id: 5, img: img5, text: <div className="card_text">All types of <span>Utensils</span> to<br /> kick start your <br /> business</div>, },
  { id: 6, img: img6, text: <div className="card_text">Training &<br /><span>Knowledge <br />Centre</span></div>, },
];

const FranchiseOverview = () => {
  const [isVisible, setIsVisible] = useState(false);
  const franchiseRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } 
      });
    });

    if (franchiseRef.current) {
      observer.observe(franchiseRef.current);
    }

    return () => {
      if (franchiseRef.current) {
        observer.unobserve(franchiseRef.current);
      }
    };
  }, []);

  return (
    <div id="franchise-overview" className="franchise-overview-section" ref={franchiseRef}>
      <div className={`franchise-overview-container`}>
        <div className={`franchise-title ${isVisible ? 'tracking-in-expand-fwd-bottom': ""}`}>
          <div><span>Franchise</span> Overview</div>
        </div>
        <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
          <div className="title-style">
            <span className="prefixTextBold">Benefits of choosing us</span>
            <span className="backgroundStyle"></span>
          </div>
        </div>
       <div className="text-container"> <div className={`franchise_text ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
          End To End <span>enablement & support to <br /> setup & run</span> Momo Cart Business. 🚀
        </div>
        <div className="franchise-work_card">
          <div className="work_btn">
            <img src={youtube_icon} alt="youtube" /> Understand How it works
          </div></div>
        </div>
        <div className="franchise_card-section">
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <FranchiseOverViewCard key={item.id} item={item}  />
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FranchiseOverview;