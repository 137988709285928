

import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import "./style.scss";
import rectangle1 from "../../asset/images/rectangle1.svg";
import rectangle2 from "../../asset/images/rectangle2.svg";
import rectangle3 from "../../asset/images/rectangle3.svg";
import right_arrow_icon from "../../asset/images/arrow_right_alt.svg";

const HowItWorks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div 
      className={`works-section`} 
      id='how-it-works' 
      ref={sectionRef}
    >
      <div className="works-container">
        <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
          <div className={`title-style`}>
            <span>How it</span> 
            <span className="secondaryTextBold"> works?</span>
            <span className="backgroundStyle"></span>
          </div>
        </div>

        <div className={`text ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
          Get started with 3 easy simple steps
        </div>

        <Row gutter={[16, 16]} className="content-section">
          {/* Step 1 */}
          <Col xs={24} md={8}>
            <div className={`content content-1`}>
              <div className="top">
                <div className="number-section">
                  <img src={rectangle2} alt='Step 1' />
                  <div className="number">1</div>
                </div>
                <div className="title">Get your Location Verified</div>
              </div>
              <div className="middle">
                Our team will visit or do a digital verification of your location and check it against more than 12 criteria. Once approved, you're ready to move forward.
              </div>
              <div className="bottom flex align-items-center gap-2">
                Checklist for location 
                <img src={right_arrow_icon} alt='Right arrow' />
              </div>
            </div>
          </Col>

          {/* Step 2 */}
          <Col xs={24} md={8}>
            <div className={`content content-2`}>
              <div className="top">
                <div className="number-section">
                  <img src={rectangle1} alt='Step 2' />
                  <div className="number">2</div>
                </div>
                <div className="title">Select your package</div>
              </div>
              <div className="middle">
                We offer flexible packages to suit your investment level and business goals. Choose the package that’s right for you and start your entrepreneurial journey today!
              </div>
              <div className="bottom flex align-items-center gap-2">
                Explore Packages 
                <img src={right_arrow_icon} alt='Right arrow' />
              </div>
            </div>
          </Col>

          {/* Step 3 */}
          <Col xs={24} md={8}>
            <div className={`content content-3`}>
              <div className="top">
                <div className="number-section">
                  <img src={rectangle3} alt='Step 3' />
                  <div className="number">3</div>
                </div>
                <div className="title">Start your Business</div>
              </div>
              <div className="middle">
                Once your location is verified, you can choose your preferred start date. Your package, along with a dedicated trained staff, will be delivered directly to your location.
              </div>
              <div className="bottom flex align-items-center gap-2">
                Apply for Franchise 
                <img src={right_arrow_icon} alt='Right arrow' />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HowItWorks;
