import React from 'react'
import "./style.scss";
import FranchiseBanner from '../../component/franchiseBanner/FranchiseBanner';
import WhoCanTakeFranchise from '../../component/whoCanTakeFranchise/WhoCanTakeFranchise';
import FranchiseOverview from '../../component/FranchiseOverview/FranchiseOverview';
import SatisfiedPartners from '../../component/SatisfiedPartners/SatisfiedPartners';
import HaveQuestions from '../../component/haveQuestions/HaveQuestions';
import ChatWithSales from '../../component/chatWithSales/ChatWithSales';
import HowItWorks from '../../component/howItWorks/HowItWorks';
import Gallery from '../../component/gallery/Gallery';
import FranchiseFeatures from '../../component/FranchiseFeatures/FranchiseFeatures';
import VideoPlayer from '../../component/videoPlayer/VideoPlayer';

const MainPage = () => {
  return (
    <>
    <FranchiseBanner />
    <VideoPlayer />
    <FranchiseFeatures />
    <WhoCanTakeFranchise />
    <FranchiseOverview />
    <HowItWorks />
    <Gallery />
    <SatisfiedPartners />
    <HaveQuestions />
    <ChatWithSales />
    </>
  )
}

export default MainPage
