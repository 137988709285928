import React from 'react'
import "./style.scss";
import CloudKitchenbanner from '../../component/cloudKitchenBanner/CloudKitchenbanner';
import WhyChooseTMM from '../../component/whyChooseTMM/WhyChooseTMM';
import CriteriaForChoosing from '../../component/criteriaForChoosing/CriteriaForChoosing';
import Achievements from '../../component/achievements/Achievements';
import UnitEconomics from '../../component/unitEconomics/UnitEconomics';
import TrainingSupport from '../../component/trainingSupport/TrainingSupport';
import ProductCategory from '../../component/productCategory/ProductCategory';
import SoftwareDrivenOperation from '../../component/softwareDrivenOperation/SoftwareDrivenOperation';
import FinancialOverview from '../../component/financialOverview/FinancialOverview';
import SocialMedia from '../../component/SocialMedia/SocialMedia';


const CloudKitchenFranchise = () => {
  return (
    <>
    <CloudKitchenbanner />
    <WhyChooseTMM />
    <CriteriaForChoosing />
    <Achievements />
    <FinancialOverview />
    <UnitEconomics />
    <TrainingSupport />
    <ProductCategory />
    <SoftwareDrivenOperation />
    <SocialMedia />
    </>
  )
}

export default CloudKitchenFranchise
