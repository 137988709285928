import React from 'react';
import "./style.scss";
import momo from "../../asset/images/momo-bg-logo.png";
import { CustomButton as Button } from '../../commonComponent/button/Button';



const LowRisk = () => {
  return (
    <div className='LowRisk-section'>
        <div className="LowRisk-container">
         <div className="logo">
            <img src={momo} alt='momo' />
         </div>
         <div className="title">Low Risk. <span>Good Profit.</span> Quick ROI.</div>
         <div className="text">Our franchise provides a low-risk, high-profit opportunity with quick returns <br />on investment, ensuring a lucrative and sustainable business venture.</div>
         <div className="button-section">
            <Button customBtnClass="btn primaryButton" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfYji4ysnQAnI3euEVW4jwyZr1uzvvUacI7zT-pR89EJ9g80A/viewform'}>Apply For Franchise</Button>
            <Button customBtnClass="btn secondSecondaryButton" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfYji4ysnQAnI3euEVW4jwyZr1uzvvUacI7zT-pR89EJ9g80A/viewform'}>Book a meeting</Button>
         </div>
        </div>
      
    </div>
  )
}

export default LowRisk
