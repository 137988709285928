import React from 'react'
import "./style.scss";

const CloudKitchenbanner = () => {
  return (
     <div className='cloud-kitchen-section'>
        <div className="cloud-kitchen-container">
        <div className="heading">
            <span>
            “
            </span>
        Cloud Kitchen Franchise at affordable price
        </div>
        <div className="text">
        Blending Exceptional Food with Innovative Technology
        </div>
        </div>
    </div>
  )
}

export default CloudKitchenbanner
