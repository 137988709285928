

import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "antd";
import "./style.scss";
import data from "./data.json";
import img1 from "../../asset/images/Vector-img-1.svg";
import img2 from "../../asset/images/vector-img-2.svg";
import stars from "../../asset/images/Star.svg";
import { CustomButton as Button } from "../../commonComponent/button/Button";

const SatisfiedPartners = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const goToNextSlide = () => {
    const newIndex = (currentSlide + 1) % data.length;
    setCurrentSlide(newIndex);
  };

  const goToPrevSlide = () => {
    const newIndex = (currentSlide - 1 + data.length) % data.length;
    setCurrentSlide(newIndex);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div 
      className={`SatisfiedPartners-section`} 
      ref={sectionRef}
    >
      <div className="SatisfiedPartners-container">
        <div className={`centered-container ${isVisible ? 'tracking-in-expand-fwd-bottom' : ''}`}>
          <div className={`title-style `}>
            <span className="prefixTextBold">Let’s Hear Directly from Our</span>{" "}
            <br />
            <span className="middleTextBold">
              Satisfied Franchise Partners
            </span>{" "}
            <span className="backgroundStyle"></span>
          </div>
        </div>
        <Row gutter={[16, 16]} className="carousel-section">
          {/* Left Side (Image) */}
          <Col xs={24} md={10} className="left_side">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                {currentSlide === index ? (
                  <img
                    src={item.img}
                    alt="img"
                    // className={isVisible ? 'text-focus-in' : ''}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Col>

          {/* Right Side (Content) */}
          <Col xs={24} md={14} className="right_side">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                {currentSlide === index ? (
                  <div className={`details-section`}>
                    <div className="stars">
                      {[...Array(5)].map((_, i) => (
                        <img key={i} src={stars} alt="stars" />
                      ))}
                    </div>
                    <div className="card_text">{item.desc}</div>
                    <div className="card_carousel_name">
                      {item.name} |{" "}
                      <span className="card_job-title">
                        {item["job-title"]}
                      </span>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
            <div className="button-section">
                <img src={img2} alt="Previous" onClick={goToPrevSlide} />
              <div>|</div>
                <img src={img1} alt="Next" onClick={goToNextSlide} />
              <Button customBtnClass="btn secondaryButton">Watch video</Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SatisfiedPartners;
