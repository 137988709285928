import React from 'react'
import "./style.scss";
import { Col, Row } from 'antd';

const data = [
    {
      id: 1,
      title: (
        <div className="title">
         Entrepreneurial Spirit:
        </div>
      ),
      text: "A passion for running a business, taking initiative, and driving growth.",
    },
    {
      id: 2,
      title: (
        <div className="title">
          Operational Discipline:
        </div>
      ),
      text: "Ability to manage day-to-day operations efficiently, adhering to established processes.",
    },
    {
      id: 3,
      title: (
        <div className="title">
          Financial Stability:
        </div>
      ),
      text: "Sufficient capital to invest in the franchise and sustain initial operations until profitability.",
    },
    {
      id: 4,
      title: (
        <div className="title">
         Customer Focus:
        </div>
      ),
      text: "Commitment to delivering excellent customer service and maintaining high standards.",
    },
    {
      id: 5,
      title: (
        <div className="title">
          Brand Alignment:
        </div>
      ),
      text: "Shared values and vision with the franchisor, ensuring brand consistency.",
    },
    {
      id: 6,
      title: (
        <div className="title">
         Commitment to Quality:
        </div>
      ),
      text: "Dedication to maintaining the quality and consistency of products and services.",
    },
  ];

const CriteriaForChoosing = () => {
  return (
    <div className='criteria-section'>
      <div className="criteria-container">
      <div className="heading">
      Criteria for Choosing the right partner
        </div>
        <div className="sub-heading">What is expected from you as a franchise partner</div>

        <Row gutter={[32, 32]} className="content">
          {" "}
          {/* Ant Design's Row component */}
          {data.map((item) => (
            <Col
              key={item.id}
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8} // Breakpoints for responsiveness
              className="content-item"
            >
             <div className="left_side">{item.id}</div>
             <div className="right_side">
             {item.title}
             <div className="text">{item.text}</div>
             </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default CriteriaForChoosing
